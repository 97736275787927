/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function TwitterIconSvg2Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 17"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M9.522 7.015L15.48.091h-1.412L8.895 6.103 4.765.091H0l6.247 9.091L0 16.442h1.412l5.461-6.348 4.363 6.349H16L9.522 7.015zM7.59 9.262l-.633-.905L1.92 1.154h2.168l4.064 5.813.633.905 5.283 7.557H11.9L7.589 9.263z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default TwitterIconSvg2Icon;
/* prettier-ignore-end */
