/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function YoutubeIconSvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 150 150"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M148.51 45s-1.46-10.34-5.98-14.88c-5.71-5.98-12.1-6.01-15.03-6.36-20.98-1.52-52.47-1.52-52.47-1.52h-.06s-31.49 0-52.47 1.52c-2.93.35-9.32.38-15.03 6.36C2.96 34.66 1.52 45 1.52 45S0 57.16 0 69.29v11.37c0 12.13 1.49 24.29 1.49 24.29s1.46 10.34 5.95 14.88c5.71 5.98 13.21 5.77 16.55 6.42C36 127.39 75 127.74 75 127.74s31.52-.06 52.5-1.55c2.93-.35 9.32-.38 15.03-6.36 4.51-4.54 5.98-14.88 5.98-14.88S150 92.82 150 80.66V69.29c0-12.13-1.49-24.29-1.49-24.29zm-89 49.45V52.29l40.52 21.15-40.52 21.01z"
        }
      ></path>
    </svg>
  );
}

export default YoutubeIconSvgIcon;
/* prettier-ignore-end */
