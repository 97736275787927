/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function PinterestIconSvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 150 150"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <defs>
        <clipPath id={"mMwSnyq3na"}>
          <path fill={"none"} d={"M0 0h150v150H0z"}></path>
        </clipPath>
      </defs>

      <g clipPath={"url(#mMwSnyq3na)"}>
        <path
          d={
            "M75 0C33.57 0 0 33.57 0 75c0 31.79 19.78 58.92 47.67 69.84-.64-5.95-1.26-15.03.26-21.5 1.38-5.86 8.79-37.27 8.79-37.27s-2.26-4.48-2.26-11.13c0-10.43 6.04-18.22 13.56-18.22 6.39 0 9.49 4.8 9.49 10.58 0 6.45-4.1 16.05-6.21 24.96-1.76 7.47 3.75 13.56 11.1 13.56 13.33 0 23.58-14.06 23.58-34.34 0-17.96-12.89-30.53-31.32-30.53-21.33 0-33.87 16-33.87 32.55 0 6.45 2.49 13.36 5.6 17.11.62.73.7 1.41.53 2.14-.56 2.37-1.85 7.47-2.08 8.5-.32 1.38-1.08 1.67-2.52 1-9.38-4.37-15.23-18.05-15.23-29.06 0-23.67 17.2-45.38 49.54-45.38 26.02 0 46.23 18.54 46.23 43.33s-16.29 46.64-38.91 46.64c-7.59 0-14.74-3.96-17.2-8.61 0 0-3.75 14.33-4.66 17.84-1.7 6.5-6.27 14.68-9.32 19.66a75.094 75.094 0 0022.21 3.34c41.43 0 75-33.57 75-75S116.43 0 75 0z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default PinterestIconSvgIcon;
/* prettier-ignore-end */
